import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import arrow_right from '../../assets/images/arrow-right.png';
import elite_logo from '../../assets/images/elite-logo.png';
import logo_white from '../../assets/images/logo_white.svg';
import { useAuthContext } from '../../hooks/use-user-auth-context.hooks';
import { dispatchUpdateNewsletterFormSignup } from '../../redux/slice/form-newsletter/form-newsletter.operations';
import { useAppDispatch } from '../../redux/store';
import logger from '../../services/logger';
import Container from '../container/Container';

export const Footer: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [newsletterSubscribed, setNewsletterSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const user = useAuthContext();
  const isAuthenticated: boolean = user ? true : false;
  useEffect(() => {
    setError('');
  }, [email]);

  const signupButtonClick = async () => {
    logger.debug('Submitted newsletter signup.', email);
    setIsLoading(true);
    const sanitizedEmail: string = email.trim().toLowerCase();
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(sanitizedEmail)) {
      await dispatch(dispatchUpdateNewsletterFormSignup({ email: sanitizedEmail }));
      setNewsletterSubscribed(true);
    } else {
      console.log('invalid valid');
      setIsLoading(false);
      setError('Invalid email address');
    }
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer bg-purple-700 pt-24 pb-4">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="footer-brand">
            <img src={logo_white} alt="" />
            <img className="w-32 mt-10" src={elite_logo} alt="" />
          </div>
          <div className="footer-nav">
            <div className="footer-heading uppercase">Vero Learning</div>
            <ul>
              <li>
                <Link onClick={handleClick} to="/about-us">
                  About
                </Link>
              </li>
              {!isAuthenticated && (
                <li>
                  <Link onClick={handleClick} to="/signup">
                    Sign Up
                  </Link>
                </li>
              )}
              <li>
                <span className="opacity-50">Student and Employees Feedback</span>
              </li>
              <li>
                <span className="opacity-50">Hiring Manager Feedback</span>
              </li>
              <li>
                <Link onClick={handleClick} to="/free-resources">
                  Free Resources
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-nav">
            <div className="footer-heading uppercase">Legal</div>
            <ul>
              <li>
                <Link onClick={handleClick} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link onClick={handleClick} to="/terms-and-conditions">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-nav">
            <div className="footer-heading uppercase">subscribe</div>
            {!newsletterSubscribed ? (
              <>
                <div className="get-updates relative">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-white p-5 border w-full shadow rounded-xl pr-16"
                    type="text"
                    maxLength={100}
                    placeholder="Get Updates"
                  />
                  <button
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={signupButtonClick}
                    disabled={isLoading}
                    className={`p-3 absolute top-3 right-3 rounded-lg ${
                      isLoading ? 'bg-gray-300' : 'bg-black'
                    }`}
                  >
                    <img className="w-6" src={arrow_right} alt="" />
                  </button>
                </div>
                {error && <p className="pt-1 text-red-500">{error}</p>}
              </>
            ) : (
              <p className="text-white">Thank You!</p>
            )}
          </div>
        </div>
        <div className="sub-footer mt-12">
          <p className="text-white text-center pt-4">
            &copy; {new Date().getFullYear()} Vero Learning LLC. All rights reserved.
          </p>
          <p className="text-white text-center pt-4">
            <small>
              {process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : ''}:
              {process.env.REACT_APP_GIT_SHA
                ? `${process.env.REACT_APP_GIT_SHA.substring(0, 7)}`
                : ''}
            </small>
          </p>
        </div>
      </Container>
    </footer>
  );
};
