import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { Options, usePDF } from 'react-to-pdf';

import ErrorPopup from '../components/ErrorPopup/ErrorPopup';
import { CircularLoader } from '../components/loader/CircularLoader';
import { FeedbackSurveyTemplate } from '../components/SWOT/PdfTemplate/FeedbackSurvey';
import { GreetingsTemplate } from '../components/SWOT/PdfTemplate/Greetings';
import { ResourcesTemplate } from '../components/SWOT/PdfTemplate/Resources';
import { SWOTIntroTemplate } from '../components/SWOT/PdfTemplate/SWOTIntro';
import { SWOTReportTemplate } from '../components/SWOT/PdfTemplate/SWOTReport';
import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { dispatchGetSWOTReportValuesForDownload } from '../redux/slice/swot/swot.operations';
import { SWOTReport } from '../redux/slice/swot/swot.types';
import { useAppDispatch } from '../redux/store';
import { isIOS } from '../utils/helper';

const options: Options = {
  filename: 'SWOT Report.pdf',
  method: 'save',
  overrides: {
    pdf: {
      unit: 'px',
      format: [800, 1123],
    },
  },
};

const SwotReportDownload: React.FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 769px)' });
  const [showPdfContent, setShowPdfContent] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const dispatch = useAppDispatch();
  const swot: SWOTReport | undefined = useSWOTContext();
  const [pdfGenerated, setPdfGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const { toPDF, targetRef } = usePDF({
    filename: 'SWOT Report.pdf',
    resolution: 1,
    // page: { margin: 1 },
  });

  useEffect(() => {
    const userIdFromParams = location.pathname.split('/')[2];
    void dispatch(dispatchGetSWOTReportValuesForDownload(userIdFromParams)).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (swot && targetRef.current && !pdfGenerated) {
      setPdfGenerated(true);
    }
  }, [swot, targetRef, pdfGenerated, toPDF, isIOS()]);

  const handleDownload = async () => {
    try {
      if (pdfGenerated) {
        setIsDownloading(true);
        if (isDesktop) {
          // eslint-disable-next-line @typescript-eslint/await-thenable
          await toPDF(options);
        } else {
          setShowPdfContent(false);
        }
        setIsDownloading(false);
      }
    } catch (error) {
      console.log('error:', error);
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (!showPdfContent) {
      (async () => {
        // eslint-disable-next-line @typescript-eslint/await-thenable
        await toPDF(options);
        setShowPdfContent(true);
      })();
    }
  }, [showPdfContent]);

  return (
    <div>
      {isLoading ? (
        <CircularLoader />
      ) : !swot ? (
        <ErrorPopup>
          <div>SWOT report is not available</div>
        </ErrorPopup>
      ) : (
        <>
          {/* for desktop and ipad view pdf content */}
          <div
            className={`mx-auto w-full max-w-3xl bg-white-100 px-5  border-l border-r relative bg-white`}
            ref={isDesktop ? targetRef : null}
          >
            {/* for desktop view pdf content */}
            <GreetingsTemplate />
            <SWOTIntroTemplate />
            <SWOTReportTemplate />
            <FeedbackSurveyTemplate />
            <ResourcesTemplate />
          </div>
          <div
            hidden={showPdfContent}
            className={`mx-auto w-full max-w-3xl bg-white-100 px-5  border-l border-r relative bg-white`}
            ref={!isDesktop ? targetRef : null}
            style={{ width: '800px' }}
          >
            {/* for mobile view pdf content */}
            <GreetingsTemplate />
            <SWOTIntroTemplate />
            <SWOTReportTemplate />
            <FeedbackSurveyTemplate />
            <ResourcesTemplate />
          </div>
          {isDownloading ? (
            <div className=" text-black p-1 px-1 rounded-xl pos-dwnld-btn">
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <button
              className="bg-black text-white px-4 p-2 rounded-xl pos-dwnld-btn"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleDownload}
              // disabled={isDownloading}
            >
              <FileDownloadIcon />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default SwotReportDownload;
