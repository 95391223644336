import { TableFooter } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

interface Row {
  name: string;
  week1: number;
  week2: number;
  week3: number;
  week4: number;
  week5: number;
}

function createData(
  name: string,
  week1: number,
  week2: number,
  week3: number,
  week4: number,
  week5: number,
): Row {
  return { name, week1, week2, week3, week4, week5 };
}

const rows: Row[] = [
  createData('Mon', 0, 0, 0, 0, 0),
  createData('Tue', 0, 0, 0, 0, 0),
  createData('Wed', 0, 0, 0, 0, 0),
  createData('Thu', 0, 0, 0, 0, 0),
  createData('Fri', 0, 0, 0, 0, 0),
  createData('Sat', 0, 0, 0, 0, 0),
  createData('Sun', 0, 0, 0, 0, 0),
];

export const PlatformEngagementTable: React.FC = () => {
  return (
    <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
      <Table sx={{ fontSize: '13px', textAlign: 'center' }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Week</TableCell>
            <TableCell align="center">1st-7th</TableCell>
            <TableCell align="center">8th-14th</TableCell>
            <TableCell align="center">15th-21th</TableCell>
            <TableCell align="center">22th-28th</TableCell>
            <TableCell align="center">28th-End</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.week1}</TableCell>
              <TableCell align="center">{row.week2}</TableCell>
              <TableCell align="center">{row.week3}</TableCell>
              <TableCell align="center">{row.week4}</TableCell>
              <TableCell align="center">{row.week5}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ '&:last-child td': { border: 0 } }}>
            <TableCell className="!text-purple-700 !font-bold !text-sm">Total Hours</TableCell>
            <TableCell className="!text-sm !font-bold" align="center">
              0
            </TableCell>
            <TableCell className="!text-sm !font-bold" align="center">
              0
            </TableCell>
            <TableCell className="!text-sm !font-bold" align="center">
              0
            </TableCell>
            <TableCell className="!text-sm !font-bold" align="center">
              0
            </TableCell>
            <TableCell className="!text-sm !font-bold" align="center">
              0
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
