import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import logger from '../../../services/logger';

import { UserProfileDTO } from './user-profile.types';

export type UserProfileState = Partial<UserProfileDTO>;

const initialState: UserProfileState = {
  name: '',
  phoneCountryCode: '+1',
  phone: '',
  email: '',
  dateOfBirth: undefined, //Timestamp.fromDate(new Date()),
  goals: [],
  profile_img: '',
  searchingJob: 'No',
  zip: '',
  sharedWithHiringManager: false,
  asaUserId: undefined,
  isPremium: undefined,
  subscriptionExpiryDate: undefined,
  paymentProcessing: undefined,
};

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    updateUserProfileInfo: (state, action: PayloadAction<UserProfileDTO>) => {
      logger.debug('Updating user profile state.', {
        state,
        action,
      });
      Object.assign(state, { ...action.payload });
    },
  },
});

export const { updateUserProfileInfo: updateUserProfileInfoAction } = userProfileSlice.actions;
export default userProfileSlice.reducer;
