import {
  AddCircle,
  Cake,
  CheckCircle,
  Download,
  Info,
  MarkunreadMailbox,
  Phone,
  VisibilityOff,
  Work,
} from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';

import interestCategoryIcon from '../assets/icons/interestCategory.png';
import interestChartIcon from '../assets/icons/interestChart.png';
import Pdf from '../assets/images/dashboard/Pdf.svg';
import Word from '../assets/images/dashboard/Word.svg';
import avatar from '../assets/images/Muser.jpg';
import opportunities from '../assets/images/swot/opportunities.svg';
import strength from '../assets/images/swot/strength-icon.svg';
import threats from '../assets/images/swot/threats-icon.svg';
import weekness from '../assets/images/swot/weekness-icon.svg';
import { AuthenticatedPage } from '../components/AuthenticatedPage';
import Container from '../components/container/Container';
import { ImprovementBarChart } from '../components/Dashboard/ImprovementBarChart';
import { NaturalCompetenciesPieChart } from '../components/Dashboard/NaturalCompetenciesPieChart';
import { PlatformEngagementTable } from '../components/Dashboard/PlatformEngagementTable';
import { ProfileCard } from '../components/Dashboard/ProfileCard';
import { SfetsPieChart } from '../components/Dashboard/SfetsPieChart';
import { StackableSkillsBarChart } from '../components/Dashboard/StackableSkillsBarChart';
import { FeedbackSurveyTemplate } from '../components/SWOT/PdfTemplate/FeedbackSurvey';
import { GreetingsTemplate } from '../components/SWOT/PdfTemplate/Greetings';
import { ResourcesTemplate } from '../components/SWOT/PdfTemplate/Resources';
import { SWOTIntroTemplate } from '../components/SWOT/PdfTemplate/SWOTIntro';
import { SWOTReportTemplate } from '../components/SWOT/PdfTemplate/SWOTReport';
import { courses } from '../courses/courses';
import { useIntakeFormContext } from '../hooks/use-form-context.hooks';
import { useSWOTContext } from '../hooks/use-swot-context.hooks';
import { useCourseProgressContext } from '../hooks/use-user-course.hooks';
import { useUserProfileContext } from '../hooks/user-user-profile-context.hooks';
import { dispatchGetIntakeFormValues } from '../redux/slice/form-intake/form-intake.operations';
import { SkillsFields } from '../redux/slice/form-skills';
import { dispatchUpsertPreEmploymentAssessment } from '../redux/slice/pre-employment-assessment';
import { dispatchGetSWOTReportValues } from '../redux/slice/swot/swot.operations';
import { SWOTCategory, SWOTReport as SWOTReportType } from '../redux/slice/swot/swot.types';
import { dispatchGetChapterProgress } from '../redux/slice/user-course';
import { dispatchGetUserProfileValues } from '../redux/slice/user-profile';
import { useAppDispatch } from '../redux/store';

const UserProfile: React.FC = () => {
  const { toPDF, targetRef } = usePDF({ filename: 'SWOT.pdf' });
  const dispatch = useAppDispatch();
  const courseProgressContext = useCourseProgressContext();
  const { name, phoneCountryCode, phone, email } = useIntakeFormContext();
  const userProfileContext = useUserProfileContext();
  const [showPdfContent, setShowPdfContent] = useState(false);
  const [showDownloading, setShowDownloading] = useState(false);
  function mapVariableToReadableString(key: keyof SkillsFields): string {
    switch (key) {
      case 'skillCommunication':
        return 'Communication';
      case 'skillCulture':
        return 'Culture';
      case 'skillMotivation':
        return 'Motivation';
      case 'skillPlanning':
        return 'Planning';
      case 'skillProblemSolving':
        return 'Problem Solving';
      case 'skillProfessionalism':
        return 'Professionalism';
      case 'skillReliability':
        return 'Reliability';
      case 'skillStressTolerance':
        return 'Stress Tolerance';
      case 'skillTeamwork':
        return 'Teamwork';
      case 'skillTechnology':
        return 'Technology';
    }
  }

  useEffect(() => {
    if (!Object.keys(courseProgressContext).length) {
      void dispatch(dispatchGetChapterProgress());
    }
  }, [courseProgressContext]);
  useEffect(() => {
    void dispatch(dispatchGetUserProfileValues());
    void dispatch(dispatchGetIntakeFormValues());
    void dispatch(dispatchUpsertPreEmploymentAssessment());
    void dispatch(dispatchGetSWOTReportValues());
  }, []);

  const swot: SWOTReportType | undefined = useSWOTContext();
  const handleExportPDF = () => {
    setShowPdfContent(true);
    setShowDownloading(true);
    setTimeout(() => {
      toPDF();
      setShowPdfContent(false);
    }, 100);
    setTimeout(() => {
      setShowDownloading(false);
    }, 800);
  };
  return (
    <>
      <div className="dashboard">
        <Container>
          <div className="flex items-center justify-between mb-5">
            <h2 className="dashboard-heading">User Profile</h2>
            <Link className="btn-primary cursor-pointer" to="/update-profile">
              Update Profile
            </Link>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
            <ProfileCard
              heading="PERSONAL INFORMATION"
              buttonText="No Button Here"
              buttonIcon={<AddCircle />}
              isVisible={false}
            >
              <div className="md:flex ">
                <div className="user md:text-center flex md:block items-center md:w-1/2">
                  <img
                    className="h-20 md:h-36 w-20 md:w-36 object-cover rounded-full mb-3 md:mx-auto mx-0"
                    src={avatar}
                    alt=""
                  />
                  <div className="ml-4 md:ml-0">
                    <p className="text-lg font-bold mb-1">{userProfileContext?.name || name}</p>
                    <p className="text-sm break-all">{userProfileContext?.email || email}</p>
                  </div>
                </div>
                <div className="basic-details md:w-1/2 text-sm mt-5 md:mt-0">
                  <div className="flex items-center mb-5">
                    <Phone
                      sx={{
                        color: '#962CC6',
                        border: '1px solid #ccc',
                        height: '30px',
                        width: '30px',
                        borderRadius: '50%',
                        padding: '5px',
                      }}
                    />
                    <div className="ml-3">
                      <p className="font-light text-gray-500 text-sm">Phone</p>
                      <p className="font-semibold">
                        {userProfileContext?.phoneCountryCode || phoneCountryCode}{' '}
                        {userProfileContext?.phone || phone}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center mb-5">
                    <Cake
                      sx={{
                        color: '#962CC6',
                        border: '1px solid #ccc',
                        height: '30px',
                        width: '30px',
                        borderRadius: '50%',
                        padding: '5px',
                      }}
                    />
                    <div className="ml-3">
                      <p className="font-light text-gray-500 text-sm">Birthday</p>
                      <p className="font-semibold">
                        {userProfileContext?.dateOfBirth
                          ? dayjs(userProfileContext?.dateOfBirth?.toISOString()).format(
                              'MMMM DD, YYYY',
                            )
                          : ''}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center mb-5">
                    <MarkunreadMailbox
                      sx={{
                        color: '#962CC6',
                        border: '1px solid #ccc',
                        height: '30px',
                        width: '30px',
                        borderRadius: '50%',
                        padding: '5px',
                      }}
                    />
                    <div className="ml-3">
                      <p className="font-light text-gray-500 text-sm">Zip Code</p>
                      <p className="font-semibold">{userProfileContext?.zip || ''}</p>
                    </div>
                  </div>
                  <div className="flex items-center mb-5">
                    <Work
                      sx={{
                        color: '#962CC6',
                        border: '1px solid #ccc',
                        height: '30px',
                        width: '30px',
                        borderRadius: '50%',
                        padding: '5px',
                      }}
                    />
                    <div className="ml-3">
                      <p className="font-light text-gray-500 text-sm">Actively looking for a job</p>
                      <p className="font-semibold">{`${userProfileContext?.searchingJob}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ProfileCard>
            <ProfileCard
              disabled={true}
              heading="User Documents"
              buttonText=""
              buttonIcon={<AddCircle />}
              isVisible={false}
            >
              <div className="border rounded-lg px-5 py-4 flex items-center justify-between mb-3 item-disabled">
                <div className="flex items-center">
                  <img src={Word} alt="" />
                  <p className="ml-5">Resume.docx</p>
                </div>
                <button disabled className="text-blue-500 disabled">
                  <Download />
                  <span className="ml-2">Download</span>
                </button>
              </div>
              <div className="border rounded-lg px-5 py-4 flex items-center justify-between mb-3 item-disabled">
                <div className="flex items-center">
                  <img src={Pdf} alt="" />
                  <p className="ml-5">background check.pdf</p>
                </div>
                <button disabled className="text-blue-500 disabled">
                  <Download />
                  <span className="ml-2">Download</span>
                </button>
              </div>
            </ProfileCard>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
            <div className="grid gap-5">
              <ProfileCard
                heading="GOAL SETTING"
                buttonText=""
                buttonIcon={<AddCircle />}
                isVisible={false}
              >
                {userProfileContext?.goals?.length ? (
                  userProfileContext?.goals?.map((goal, index) => (
                    <div
                      key={index}
                      className="border rounded-lg px-5 py-4 flex items-start justify-between mb-3"
                    >
                      <div className="">
                        <p className="font-bold mb-2">Goal {index + 1}</p>
                        <p className="break-all">{goal.goal}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-between gap-10">
                    <h2 className="font-bold text-xl">No Goals added</h2>
                  </div>
                )}
              </ProfileCard>
              <ProfileCard
                heading="Profile Information visibility"
                buttonText="Hide"
                buttonIcon={<VisibilityOff />}
                isVisible={false}
              >
                <div className="flex justify-between">
                  <p>Profile Information With Hiring Managers</p>
                  <p className="text-purple-600 font-bold ml-5">{`${
                    userProfileContext?.sharedWithHiringManager ? 'Yes' : 'No'
                  }`}</p>
                </div>
              </ProfileCard>
            </div>
            <ProfileCard
              heading="Course Progresses"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              {_.orderBy(_.values(courseProgressContext).filter((x) => x.progress > 0)).length ? (
                <div className="max-h-[510px] overflow-auto">
                  {_.orderBy(
                    _.values(courseProgressContext)
                      .filter((x) => x.progress > 0)
                      .map((course) => ({
                        ...course,
                        courseId:
                          _.findKey(courseProgressContext, {
                            activeChapter: course.activeChapter,
                          }) || '',
                      })),
                    'progress',
                    'asc',
                  ).map((course) => (
                    <div
                      key={course.courseId}
                      className="border rounded-lg px-5 py-4 flex items-center justify-between mb-3"
                    >
                      <div className="flex items-center">
                        {course.progress < 100 ? (
                          <Info sx={{ color: '#ECA952' }} />
                        ) : (
                          <CheckCircle sx={{ color: '#2CB22C' }} />
                        )}
                        <p className="ml-5">
                          {_.find(courses, (x) => x.id === course.courseId)?.name || ''}
                        </p>
                      </div>
                      <p className="text-3xl font-light">{course.progress}%</p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex justify-between gap-10">
                  <h2 className="font-bold text-xl">No Course in progress</h2>
                </div>
              )}
            </ProfileCard>
          </div>

          <div className="bg-white rounded-3xl md:px-8 md:py-6 p-4">
            <div className="flex justify-between mb-7">
              <h2 className="uppercase text-gray-400 tracking-widest">SWOT ANALYSIS</h2>
              {!showDownloading ? (
                <Link to="#" className="text-blue-500" onClick={() => handleExportPDF()}>
                  <Download />
                  <span className="ml-2">Download</span>
                </Link>
              ) : (
                <CircularProgress size={30} />
              )}
            </div>
            <div className="profile-card-body">
              {swot && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 relative">
                  <div className="swot-card text-center border border-dashed border-[#663183] bg-[#FAF2FF] rounded-3xl p-5 min-h-[350px] relative">
                    <img className="mx-auto" src={strength} alt="" />
                    <p className="text-xl text-[#9169DF] my-5 font-bold">Super Powers</p>
                    <ul className="text-sm">
                      {swot?.[SWOTCategory.STRENGTHS].length ? (
                        swot[SWOTCategory.STRENGTHS].map((value: keyof SkillsFields) => (
                          <li className="mb-3" key={value}>
                            <Link
                              className="link"
                              target="_blank"
                              to={`/my-resources/${mapVariableToReadableString(value)}`}
                            >
                              {mapVariableToReadableString(value)}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>None</li>
                      )}
                    </ul>
                  </div>
                  <div className="swot-card text-center border border-dashed border-[#F9B23A] bg-[#FFFAF2] rounded-3xl p-5 min-h-[350px] relative">
                    <img className="mx-auto" src={weekness} alt="" />
                    <p className="text-xl text-[#F9B33A] my-5 font-bold">Weaknesses</p>
                    <ul className="text-sm">
                      {swot?.[SWOTCategory.WEAKNESSES].length ? (
                        swot[SWOTCategory.WEAKNESSES].map((value: keyof SkillsFields) => (
                          <li className="mb-3" key={value}>
                            <Link
                              className="link"
                              target="_blank"
                              to={`/my-resources/${mapVariableToReadableString(value)}`}
                            >
                              {mapVariableToReadableString(value)}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>None</li>
                      )}
                    </ul>
                  </div>
                  <div className="swot-card text-center border border-dashed border-[#89D2F5] bg-[#F2FBFF] rounded-3xl p-5 min-h-[350px] relative">
                    <img className="mx-auto" src={opportunities} alt="" />
                    <p className="text-xl text-[#89D2F5] my-5 font-bold">Opportunities</p>

                    <ul className="text-sm leading-9">
                      {swot?.[SWOTCategory.OPPORTUNITIES].length ? (
                        swot[SWOTCategory.OPPORTUNITIES].map((value: keyof SkillsFields) => (
                          <li className="mb-3" key={value}>
                            <Link
                              className="link"
                              target="_blank"
                              to={`/my-resources/${mapVariableToReadableString(value)}`}
                            >
                              {mapVariableToReadableString(value)}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>None</li>
                      )}
                    </ul>
                  </div>
                  <div className="swot-card text-center border border-dashed border-[#E72F79] bg-[#FFF2F7] rounded-3xl p-5 min-h-[350px] relative">
                    <img className="mx-auto" src={threats} alt="" />
                    <p className="text-xl text-[#E52F77] my-5 font-bold">Threats</p>
                    <ul className="text-sm leading-9">
                      {swot?.[SWOTCategory.THREATS].length ? (
                        swot[SWOTCategory.THREATS].map((value: keyof SkillsFields) => (
                          <li className="mb-3" key={value}>
                            <Link
                              className="link"
                              target="_blank"
                              to={`/my-resources/${mapVariableToReadableString(value)}`}
                            >
                              {mapVariableToReadableString(value)}
                            </Link>{' '}
                          </li>
                        ))
                      ) : (
                        <li>None</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
            <ProfileCard
              heading="PLATFORM ENGAGEMENT"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              <PlatformEngagementTable />
            </ProfileCard>
            <ProfileCard
              disabled={true}
              heading="SCORES FOR EACH TRANSFERABLE SKILL"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              <SfetsPieChart />
            </ProfileCard>
            <ProfileCard
              disabled={true}
              heading="IMPROVEMENT"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              <ImprovementBarChart />
            </ProfileCard>
            <ProfileCard
              disabled={true}
              heading="NATURAL COMPETENCIES"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              <NaturalCompetenciesPieChart />
            </ProfileCard>
            <ProfileCard
              disabled={true}
              heading="STACKABLE SKILLS"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              <StackableSkillsBarChart />
            </ProfileCard>
            <ProfileCard
              disabled={true}
              heading="INTERESTS"
              buttonText="Hide"
              buttonIcon={<VisibilityOff />}
              isVisible={false}
            >
              <div className="flex items-center gap-5 justify-center">
                <img src={interestChartIcon} alt="" />
                <img src={interestCategoryIcon} alt="" />
              </div>
            </ProfileCard>
          </div>
          <div
            className={`${
              !showPdfContent ? 'hidden' : ''
            } mx-auto w-full max-w-3xl bg-white-100 px-5 shadow-xl border-l border-r relative bg-white`}
            ref={targetRef}
          >
            <GreetingsTemplate />
            <SWOTIntroTemplate />
            <SWOTReportTemplate />
            <FeedbackSurveyTemplate />
            <ResourcesTemplate />
          </div>
        </Container>
      </div>
    </>
  );
};

export const UserProfilePage: React.FC = () => {
  return <AuthenticatedPage render={() => <UserProfile />} />;
};
