import { Analytics, getAnalytics as initializeAnalytics } from 'firebase/analytics';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';

import { EnvironmentVariable } from '../../utils/constants';

import { registerAuthObserver } from './auth';

let app: FirebaseApp;
let analytics: Analytics;
let db: Firestore;

/**
 * initializes the Firebase and analytics applications
 */
export function initializeFirebase(): void {
  if (app) {
    // app + analytics already initialized
    return;
  }

  const options: FirebaseOptions = {
    apiKey: process.env[EnvironmentVariable.REACT_APP_FIREBASE_API_KEY],
    authDomain: process.env[EnvironmentVariable.REACT_APP_FIREBASE_AUTH_DOMAIN],
    projectId: process.env[EnvironmentVariable.REACT_APP_FIREBASE_PROJECT_ID],
    storageBucket: process.env[EnvironmentVariable.REACT_APP_FIREBASE_STORAGE_BUCKET],
    messagingSenderId: process.env[EnvironmentVariable.REACT_APP_FIREBASE_STORAGE_BUCKET],
    appId: process.env[EnvironmentVariable.REACT_APP_FIREBASE_APP_ID],
    measurementId: process.env[EnvironmentVariable.REACT_APP_FIREBASE_MEASUREMENT_ID],
  };

  app = initializeApp(options);
  analytics = initializeAnalytics(app);
  db = getFirestore(app);

  registerAuthObserver();
}

/**
 * gets the Firebase instance
 */
export function getApp(): FirebaseApp {
  if (!app) {
    initializeFirebase();
  }

  return app;
}

/**
 * gets the Firebase instance
 */
export function getFirebaseApp(): FirebaseApp {
  if (!app) {
    initializeFirebase();
  }

  return app;
}

/**
 * gets the analytics library
 */
export function getAnalytics(): Analytics {
  if (!analytics) {
    initializeFirebase();
  }

  return analytics;
}

/**
 * gets the firestore database
 */
export function getFirestoreDatabase(): Firestore {
  if (!db) {
    initializeFirebase();
  }

  return db;
}
