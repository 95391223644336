import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useTempAuthContect } from '../../hooks/use-user-auth-context.hooks';
import { useUserProfileContext } from '../../hooks/user-user-profile-context.hooks';
import {
  UserAsaTaskHistory,
  dispatchGetAsaTaskHistoryValues,
} from '../../redux/slice/asa-task-history';
import { setOTPAuthAction } from '../../redux/slice/auth';
import { clearUserTempData, dispatchVerifyLogin } from '../../redux/slice/auth/auth.operations';
import { dispatchOtpEmail } from '../../redux/slice/form-otp-validation/form-otp-validation.operations';
import { dispatchGetUserProfileValues } from '../../redux/slice/user-profile';
import { useAppDispatch } from '../../redux/store';
import {
  getOtpFromSessionStorage,
  isOtpVerified,
  isTokenExpired,
  removeOtpFromSessionStorage,
  sendOtpToEmail,
} from '../../services/database/mfa-auth';
import { EnvironmentVariable } from '../../utils/constants';

import { CountdownTimer } from './CountDownTimer';

const LoginSchema = yup
  .object({
    otp: yup
      .string()
      .trim()
      .required('Required')
      .test('len', 'Must be exactly 5 characters', (val) => val?.length === 6),
  })
  .required();

export const OtpVerfication: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const user = useTempAuthContect();
  const navigate = useNavigate();
  const [generateOtp, setGenerateOtp] = useState(true);
  const [newError, setNewError] = useState('');
  const userProfileContext = useUserProfileContext();
  const otpRegenrationTime: number =
    Number(process.env[EnvironmentVariable.REACT_APP_OTP_REGENRATE_TIME]) * 60;
  interface IFormInput {
    otp: string;
  }
  useEffect(() => {
    setIsLoading(false);
    void dispatch(dispatchGetUserProfileValues());
  }, []);

  useEffect(() => {}, [userProfileContext]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(LoginSchema),
  });
  const handleLogin: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true);

    try {
      const { sessionOtp, sessionExpTime } = await getOtpFromSessionStorage();
      const isOtpExpired = await isTokenExpired(sessionExpTime);
      if (data.otp === sessionOtp.toString() && !isOtpExpired) {
        dispatch(setOTPAuthAction());
        await isOtpVerified(true);
        if (user) {
          await dispatch(dispatchVerifyLogin(user));
          await dispatch(clearUserTempData());
          await removeOtpFromSessionStorage();
        }
        if (user?.claims.isAsaUser) {
          // const asaParamObj = asaParams && JSON.parse(asaParams);
          const taskHistory: UserAsaTaskHistory | undefined = await dispatch(
            dispatchGetAsaTaskHistoryValues(),
          );

          if (taskHistory) {
            //:TODO redirect to payment page
            const currentTask = taskHistory.tasks.find((x) => !x.isCompleted);
            if (userProfileContext?.email && !userProfileContext?.asaUserId) {
              navigate('/pre-employment/start');
            } else {
              window.location.href = currentTask?.taskUrl
                ? currentTask?.taskUrl
                : userProfileContext?.isPremium
                ? '/pre-employment/start'
                : '/upgrade';
            }
          }
        } else {
          const previousUrl = sessionStorage.getItem('previousUrl');
          if (previousUrl) {
            sessionStorage.removeItem('previousUrl');
            navigate(previousUrl);
          } else {
            toast.success('Welcome back!');
            navigate('/pre-employment/start');
          }
        }
      } else {
        if (isOtpExpired) {
          toast.error('OTP has expired.');
          await removeOtpFromSessionStorage();
          await isOtpVerified(false);
          navigate('/login');
        }
        setNewError('Invalid Otp');
      }
    } catch (e) {
      toast.error('Incorrect login details.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (generateOtp) {
      setTimeout(() => {
        setGenerateOtp(!generateOtp);
      }, (otpRegenrationTime + 1) * 1000);
    }
  }, [generateOtp]);

  const handleRegenrateOtp = async () => {
    if (user && user.user.email) {
      setGenerateOtp(!generateOtp);

      const otp = await sendOtpToEmail();
      const otpExpTime = process.env[EnvironmentVariable.REACT_APP_OTP_EXP_TIME];
      await dispatchOtpEmail({
        To: user?.user?.email,
        otp: otp,
        validUntil: Number(otpExpTime) || 15,
      });
      toast.info('Otp is sent');
    }
  };

  useEffect(() => {
    void getOtpFromSessionStorage().then((data) => {
      if (!data) {
        navigate('/login');
      }
    });
  }, []);

  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={`mx-auto w-full max-w-lg bg-white-100 rounded-xl px-0 md:px-5 py-0 md:py-10 lg:p-10`}
      >
        <h2 className="text-center text-2xl font-bold leading-tight mb-4">
          Enter One Time Password
        </h2>
        <p className="text-gray-800 text-center text-sm mb-3">
          We just sent a 6 digit OTP to{' '}
          <strong className="text-purple-500">{user?.user?.email}</strong>{' '}
        </p>
        <div className="space-y-5">
          <Box
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(handleLogin)}
            component="form"
            sx={{
              '& > :not(style)': { width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl margin="normal">
              <Controller
                control={control}
                name="otp"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="otp"
                    type="text"
                    label="One Time Password"
                    variant="outlined"
                    onChange={(event) => {
                      const newValue = event.target.value.slice(0, 6); // Enforce max length manually
                      onChange(newValue);
                    }}
                    value={value}
                    inputProps={{
                      pattern: '^[0-9]*$',
                      onKeyPress: (event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      },

                      maxLength: 6,
                    }}
                  />
                )}
              ></Controller>

              <p className="text-red-500">{newError || errors.otp?.message || ''}</p>
            </FormControl>
            <FormControl margin="normal">
              <button type="submit" className="btn-primary w-full" disabled={isLoading}>
                Sign In
              </button>
            </FormControl>
          </Box>
          <div className="text-center text-gray-800 gap-2  md:text-sm">
            <div>
              <p>Didn't get OTP? </p>
            </div>
            <div className="flex justify-center gap-2 mt-1">
              {' '}
              <button
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleRegenrateOtp}
                className="text-purple-500 disabled:text-gray-400"
                disabled={generateOtp}
              >
                <span>Regenerate OTP </span>
              </button>
              <span>
                <CountdownTimer
                  isRegenerated={generateOtp}
                  timer={otpRegenrationTime}
                  onRegenerate={() => setGenerateOtp(false)}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
