import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';

import { login, logout, signup } from '../../../services/database';
import { isOtpVerified, removeOtpFromSessionStorage } from '../../../services/database/mfa-auth';
import { clearTempLoginData, tempLogin } from '../auth/temp-auth.slice';

import { loginAction, logoutAction, setOTPAuthAction } from './auth.slice';
import { LoginDTO, SignupDTO } from './auth.types';
import { CustomUser } from './CustomUser';

/**
 * registers a new user's account
 */
export const signupUser = async (dto: SignupDTO) => {
  const user: CustomUser = await signup(dto);

  return user;
};

/**
 * registers a new user's account
 */
export const dispatchSignup =
  (dto: SignupDTO) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    const user: CustomUser = await signup(dto);
    if (user) {
      dispatch(tempLogin(user));
    }
    //dispatch(loginAction(user));
    return user;
  };

/**
 * login to a user's account
 */
export const dispatchLogin =
  (dto: LoginDTO) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    const user: CustomUser = await login(dto);
    if (user) {
      dispatch(tempLogin(user));
    }
    // dispatch(loginAction(user));
    return user;
  };

/**
 * login to a user's account
 */
export const dispatchLogout =
  () =>
  async (dispatch: Dispatch<PayloadAction<void>>): Promise<void> => {
    await logout();
    await removeOtpFromSessionStorage();
    await isOtpVerified(false);
    dispatch(logoutAction());
  };

export const dispatchUpdateUserState =
  (user: CustomUser) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    dispatch(loginAction(user));
    return user;
  };

export const clearUserTempData =
  () =>
  async (dispatch: Dispatch<PayloadAction<void>>): Promise<void> => {
    dispatch(clearTempLoginData());
  };

export const dispatchVerifyLogin =
  (dto: CustomUser) =>
  async (dispatch: Dispatch<PayloadAction<CustomUser>>): Promise<CustomUser> => {
    if (dto) {
      dispatch(loginAction(dto));
    }
    return dto;
  };

export const dispatchOtp = () => {
  async (dispatch: Dispatch<PayloadAction<void>>): Promise<void> => {
    dispatch(setOTPAuthAction());
  };
};
